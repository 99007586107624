import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

export default () => (
  <section id="service" className="section">
    <div className="container">
      <div className="columns is-vcentered">
        <div className="column is-6">
          <h1 className="title is-capitalized has-text-danger has-text-left-desktop  is-size-1 is-size-2-mobile">What is our core competency</h1>
          <h2 className="subtitle has-text-black has-text-left-desktop  is-size-2 is-size-3-mobile">We shape your digital strategy, design, build, & market your product to better serve your Chinese clients and increase ROI.</h2>
          <Link className="has-text-danger has-text-left-desktop  is-size-3 is-size-3-mobile" to="/services/">Learn more about our services</Link>
        </div>
        <div className="column is-6">
          <figure className="image is-4by3">
            <img src="/img/digital-marketing-consulting.svg" alt="china digital marketing consulting"/>
          </figure>
        </div>
      </div>  
      <hr/>
      <div className="columns is-vcentered">
        <div className="column is-6">
          <figure className="image is-4by3">
            <img src="/img/our-work.svg" alt="our work"/>
          </figure>
        </div>
        <div className="column is-6">
          <h1 className="title is-capitalized has-text-danger has-text-left-desktop is-size-1 is-size-2-mobile">Our work</h1>
          <h2 className="subtitle has-text-black has-text-left-desktop  is-size-2 is-size-3-mobile">Get to know our case study.</h2>
          <Link className="has-text-danger has-text-left-desktop  is-size-3 is-size-3-mobile" to="/work/">Learn more about our work</Link>
        </div>
      </div>  
    </div>
  </section>

)