import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import Service from '../components/service'
import Contact from '../components/contact'
import SiteMeta from '../components/meta-data'


class RootIndex extends React.Component {
 
  render() {
    const metaData = get(this.props, 'data.site.siteMetadata.homePage')
    const servicesNav = get(this.props,'data.allContentfulOurServices.edges')
    const data = {
      hero: 'hero-home',
      size: 'is-fullheight',
      isHidden: false,
      text: 'we are your digital partner for greater china market',
      servicesNav
    }

    return (
      <Layout location={this.props.location} data={data} >
        <SiteMeta metaData={metaData}/>
        <Service/>
        <Contact/>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomePage {
    site {
      siteMetadata {
        homePage {
          title
          description
          imageUrl
          siteUrl
        }
      }
    } 
    allContentfulOurServices(filter: {node_locale: {eq: "en-GB"}}, sort: {order: ASC, fields: order}) {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`